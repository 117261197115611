<template >
  <div>
    <TrabalheConosco />
  </div>
</template>

<script>
import TrabalheConosco from "../components/trabalheConosco/trabalheConosco.vue";
export default {
  components: {
    TrabalheConosco,
  },
};
</script>
<style scoped>
</style>