<template>
 <div class="row p-4 m-0" style="width: 100vw; position: fixed; top: 0; z-index: 100;" :style="{background: scrollY > 50 ? '#000000dd' : ''}">
      <div class="col-6">
        <img
          src="@/assets/logotipo.png"
          alt="MG COBRANÇAS"
          class="img-fluid img"
        />
      </div>
      <div @click="menu" class="col text my-auto pr-5" style="padding-right: 50px">
        <span class="span-menu my-auto">MENU</span>
        <img src="@/assets/icons/icon-menu.svg" class="icon-menu"/>
      </div>
    </div>
</template>


<script>
export default {
  name: "TopMenu",
  data() {
    return {
      showMenu: false,
      scrollY: 0
    };
  },
  methods: {
    menu() {
      this.$router.push({
        name: "Menu",
      });
    },
    onScroll() {
      this.scrollY = window.top.scrollY;
    }
  },
  mounted() {
  window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
};
</script>

<style scoped>
@media (max-width: 450px) {
  .icon-menu{
    margin-top: -80px;
  }
}
@media (max-width: 767.98px) {
  .menu-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text {
    margin-left: 100px;
  }
}

.icon-menu{
  width: 25px; 
  height: auto
}
.img {
  width: 180px;
  height: auto;
  /* filter: brightness(0%) invert(100%); */
}
.text {
  display: flex;
  flex-direction: row;
  justify-content: end;
  opacity: 1;
}
.text:hover {
  cursor: pointer;
}
.text .span-menu {
  margin-right: 10px;
  opacity: 0;
  transition: all ease-out 0.5s;
}

.text:hover .span-menu {
  opacity: 1;
  transition: all ease-out 0.5s;
}
</style>