<template>
  <div>
    <TopMenu />
    <div style="padding-top: 20vh">

      <div class="container tracking-in-contract-bck text-center">
        <div class="row">
          <div class="col ">
            <h1 style="color: #f54308">
            Trabalhe conosco
            </h1>
          </div>
        </div>
      </div>

      <!-- Tópicos -->
      <div class="container mt-5 tracking-in-contract-bck text-center">
        <div class="col">
          <div class="col-md-12 mt-5">
            <p style="font-size: 24px">
              Faça parte da nossa equipe!<br>
              Envie o seu currículo!<br>
            </p>
            <h4>
                <i class="bi bi-whatsapp" style="color: #f54308"></i> <a style="color: #f54308" target="_blank" href="https://api.whatsapp.com/send?phone=5535992520777&text=Ol%C3%A1%2C%20gostaria%20de%20enviar%20meu%20curriculo">(35) 99252-0777</a>
            </h4>
          </div>

        </div>
      </div>
      <!-- Tópicos -->

    </div>


  </div>
</template>

<script>

import TopMenu from "../menu/topMenu.vue";

export default {
  name: "TrabalheConosco",
  data() {
    return {
     
    };
  },
  components: {
    TopMenu,
  },
  methods:{    
  }
};
</script>


<style scoped>
h1,h4{
  text-transform: uppercase;
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
.div-row {
  display: flex;
  flex-direction: row;
}

.count_empresas{
  color: #f54308;
  font-weight: bold;
  font-size: 25px;
  border-radius: 50px;
}

.count_recuperados{
  color: #f54308;
  font-weight: bold;
  font-size: 25px;
  border-radius: 50px;
}

.count_empresas span,.count_recuperados span{
  font-size: 100px;
}

@media (max-width: 500px) {

.count_empresas span,.count_recuperados span{
  font-size: 60px;
}
}


@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>